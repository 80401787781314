<template lang="">
    <div class="position-relative">
        <div class="kind-landing-pages d-flex flex-wrap justify-content-center align-content-stretch position-relative">
            <CardColdEmail v-for="(card, index) in collection" :data="card" :select="select" :key="index"></CardColdEmail>
        </div>
    </div>
</template>
<script>
import CardColdEmail from './CardColdEmail';


export default {
    
    components: {
        CardColdEmail
    }, 

    props: {
        kind: String
    },


    data() {
        return {
            titleSuffix: 'Cold Emails: ',

            collection: [
                /**
                 * {  
                 *     title: 'Consectetur adipiscing elit',
                 *     excerpt: 'Duis ut diam quam nulla porttitor consectetur adipiscing elit',
                 *     content: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis ut diam quam nulla porttitor',
                 *     thumbnail: 'https://i.picsum.photos/id/942/400/240.jpg?hmac=QsYHNsiRkpXlyfXqw5RipKOVmcLzvgnaaMc4zyxqyA4'
                 * },
                 * ...
                 */
            ]
        }
    }, 



    methods: {
        addExtraCards(collection, object) {
            let extraCard = 3 - collection?.length%3;
                extraCard = extraCard < 3? extraCard : 0;

            for(let i=0; i<extraCard; i++) {
                collection.push(object);
            }
        },

        // async selectIntent(data) {
        //     let scope = this;
        //     await this.$store.dispatch('fetchOpportunities');
        //     if(this.$store.getters.opportunities?.length == 0) {
        //         this.$root.notify({
        //             type: 'confirm',
        //             title: 'Oops',
        //             message: 'You must create an opportunity before adding a strategy. Would you like to create an opportunity now?',
        //             callback(okay) {
        //                 if(okay) {
        //                     let path = '/library/opportunities/all';
        //                     scope.$router.push( {path : path} );
        //                 }
        //             }
        //         });
        //     } else {
        //         this.select(data);
        //     }

        // },


		select(data) {
            let modal = this.$root.modal({
                title: 'Create a new email',
                context: data,
                okay: {
                    label: 'CREATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        let request = {...component.data, ...{
                                strategy_id: data.ID, 
                                title: this.titleSuffix + component.data.title
                            }
                        };

                        this.$store.commit('SET_LOADING_STATE', true);
                        let response = await this.$store.getters.wp.createStrategyForOpportunity(request);
                        await this.$store.dispatch('reloadStrategies');
                        this.$store.commit('SET_LOADING_STATE', false);

                        if(response?.ID) {
                            this.open(response);
                        }
                    }
                }, 

            }, 'CreateStrategyContent');

            setTimeout(() => {
                modal.content().data.title = data.title + ' (new)';
                modal.content().data.excerpt = data.excerpt + '';
            }, 0);
		}, 

        open(strategy) {
            let scope = this;
			let opportunityName = strategy?.opportunity_name || this.$route.params?.opportunity;
            this.$root.notify({
                type: 'confirm',
                title: 'Congratulations',
                message: `Your strategy "${strategy.title}" has been created. Would you like to open it?`,
                callback(okay) {
                    if(okay) {
                        let path = `/edit/strategies/${opportunityName}/${strategy.kind}/${strategy.name}`;
                        scope.$router.push( {path: path} );
                    }
                }
            });
        }, 

        capabilities() {
            let root = this.$root;
			/**
			 * strategies.create
			 */
            this.$capabilities.overwriteIf(this.$capabilities.userCan('strategies.create'), false, this, 'select', function() {
				let remaining = root.$capabilities.quota('strategies');
				let message = `You have "${remaining}" strategies remaining in your account. Would you like to add more strategies?`;
				root.notify({
					title: 'Oops!',
					type: 'confirm',
					message: message,
					callback(okay) {
						if(okay) {
							root.gotoPlans();
						}
					}
				});
			});
        }
    },

    async mounted() {
        let kind = this.kind;
        this.collection = await this.$store.dispatch('fetchStrategyTemplatesKind', kind);
        
        this.addExtraCards(this.collection, {
            title: '',
            excerpt: '',
            content: '',
            thumbnail: ''
        });

        await this.$capabilities.init( ()=>{
            this.capabilities();
        });
    }
}
</script>
<style lang="">
    
</style>