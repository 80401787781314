<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 " data-cmp="ProfileMain">
        <AppHeader></AppHeader>
        <section class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12 d-flex flex-row">
                    <!-- sidebar -->
                    <div class="sidebar position-fixed">
                        <Sidebar></Sidebar>
                    </div>

                    <!-- content area -->
                    <div class="content bg-canvas d-flex flex-column flex-fill">


                        <!-- sub navigation -->
                        <div class="bg-white border-bottom border-start d-flex flex-row navbar-wrapper px-3 py-2.5 --shadow-sm">
                            <div class="align-items-center d-flex">
                                <a @click.prevent="$router.back()" class="btn btn-primary btn-sm font-size-16 px-1 rounded-pill">
                                    <span class="material-icons pull-left">
                                    chevron_left
                                    </span>
                                </a>
                            </div>


                            <div>
                                <nav class="ps-3" style="--bs-breadcrumb-divider: '›';" aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                                        <li class="breadcrumb-item">
                                            <router-link class="breadcrumb-item text-decoration-none" :to="$root.links.DASHBOARD">
                                                <span class="">{{$root.labels.DASHBOARD}}</span>
                                            </router-link>
                                        </li>

                                        <li class="breadcrumb-item">
                                            <router-link :to="$root.links.MY_OPPORTUNITIES"
                                                class="text-decoration-none"
                                                >
                                                <span>{{$root.labels.OFFERS}}</span>
                                            </router-link>
                                        </li>

                                        <li class="breadcrumb-item">
                                            Library
                                        </li>

                                        <li class="breadcrumb-item">
                                            {{$root.labels.STRATEGIES}}
                                        </li>

                                        <li class="breadcrumb-item">
                                            {{ kindLabel }}
                                        </li>

                                        <li v-if="$route.params?.strategy" class="breadcrumb-item">
                                            {{ strategyLabel }}
                                        </li>
                                    </ol>
                                </nav>
                            </div>


                            <div class="ms-auto">
                                <a class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-2 pe-3"
                                    @click="$root.importStrategy()"
                                    >
                                    <span class="end-0 material-icons-outlined pull-left">edit_note</span>
                                    <span class="ps-1">Add Custom</span>
                                </a>


                                <!-- <router-link to="/library/opportunities/all"
                                    class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-2 pe-3"
                                    :class="[ /\/library\/opportunities\//.test($route.fullPath) ? 'disabled text-muted' : '' ]"
                                    >
                                    <span class="end-0 material-icons-outlined pull-left">attach_money</span>
                                    <span>Opportunities</span>
                                </router-link> -->

                                <!-- <router-link to="/library/strategies/sales-pages"
                                    class="btn btn-sm font-size-16 btn-pill btn-primary me-2 ps-2 pe-3"
                                    :class="[ /\/library\/strategies\//.test($route.fullPath) ? 'disabled text-muted' : '' ]"
                                    >
                                    <span class="end-0 material-icons-outlined pull-left me-1">gamepad</span>
                                    <span>Strategies</span>
                                </router-link> -->
                                
                                <ButtonRecentStrategies class="d-inline"></ButtonRecentStrategies>
                            </div>
                        </div>


                        <!-- info area -->
                        <div class="overflow-scroll pb-5 pt-5 px-4 px-lg-4 shadow-inset-md">
                            <div class="content-wrapper mx-auto ">
                                <component :is="kind" :kind="$route?.params?.kind"></component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import AppHeader from "@/components/AppHeader";
import Sidebar   from "./Sidebar.vue";
import KindAds   from "./KindAds.vue"; 
import KindAi   from "./KindAi.vue"; 
import KindSalesPages from "./KindSalesPages.vue"; 
import KindColdEmails from "./KindColdEmails.vue"; 
import ButtonRecentStrategies from "@/components/ButtonRecentStrategies.vue";

export default {
    name: "StrategiessMain",

    components: {
        AppHeader,
        Sidebar,
        KindAds,
        KindAi,
        KindSalesPages,
        KindColdEmails,
        ButtonRecentStrategies,
    },

    computed:{

        kind() {
            let cmp = this.$route.params?.kind;
            switch (cmp) {
                case undefined:
                    cmp = '(?)';
                    break;
            
                default:
                    cmp = 'Kind' + cmp.split('-').map((part)=>{
                        part = part[0].toUpperCase() + part.substr(1);
                        return part;
                    }).join('');
                    break;
            }

            return cmp;
        },


        kindLabel() {
            let label = this.$route.params?.kind;
            switch (label) {
                case undefined:
                    label = '(?)';
                    break;
            
                default:
                    label = label.replace(/-/, ' ').trim();
                    label = label[0].toUpperCase() + label.substr(1);
                    break;
            }

            return label;
        },

        startegyLabel() {
            let label = this.$route.params?.strategy;
            switch (label) {
                case undefined:
                    label = '(?)';
                    break;
            
                default:
                    label = label.replace(/-/, ' ').trim();
                    label = label[0].toUpperCase() + label.substr(1);
                    break;
            }

            return label;
        }, 

        currentSection() {
            let section = this.$route.fullPath.match(/\/library\/(.*?)\//)?.[1];
            return section;
        }
    }, 
}
</script>


<style scoped>
/* .py-2\.5 {
    padding-top: 11px!important;
    padding-bottom: 11px!important;
} */

.sidebar {
   width: 320px; 
}
.content {
   margin-left: 320px; 
   height: calc(100vh - 86px);
}
.navbar-wrapper {
    padding-top: 11px!important;
    padding-bottom: 11px!important;
}
.rounded-5 {
    border-radius: .5rem!important;
}

.disabled {
    color: var(--secondary) !important;
    border-color: var(--bg-secondary) !important;
    background-color: var(--bg-secondary)!important;
}

.shadow-inset-md {
    /* box-shadow: inset 4px -4px 8px -4px rgb(0 0 0 / 10%); */
    box-shadow: inset 0 .125rem .25rem rgba(0,0,0,.075);
}
</style>