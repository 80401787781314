<template lang="">
    <div class="kind-page-wrapper">
        
        <div class="mb-4 d-flex flex-row">
            <span class="pe-2 pt-2">Category:</span>
            <select class="form-select categories"
                v-model="current.category"
                >
                <option :selected="[option == current.category? 'selected' : '']" 
                    v-for="(option, index) in categories" :key="index"
                    :class="[/Appointments|White\ Label\ Growth|Home|Coupon|Course\ \/\ Academy|Lead\ Generation|Lead\ Magnet|Sales\ \/\ Product/.test(option)? 'd-none' : '']"
                    >{{option}}</option>
            </select>
        </div>

        <div class="kind-page d-flex flex-wrap">
            <CardPage v-for="(item, index) in templates" :key="index" 
                :data="item" 
                :action="action"
                :class="[item?.previewURL == '-'? 'd-none' : '']"
                class="mb-5"></CardPage>
            
        </div>
    </div>
</template>
<script>
import CardPage from './CardPage';
import WebTemplatesData from './WebTemplatesData.js';


export default {
    
    components: {
        CardPage,
        // ModalTemplatePreview
    }, 

    props: {
        kind: String
    },


    data() {
        return {
            titleSuffix: 'Sales Pages: ',

            collection: [
                /**
                 * {  
                 *     title: 'Consectetur adipiscing elit',
                 *     excerpt: 'Duis ut diam quam nulla porttitor consectetur adipiscing elit',
                 *     content: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis ut diam quam nulla porttitor',
                 *     thumbnail: 'https://i.picsum.photos/id/942/400/240.jpg?hmac=QsYHNsiRkpXlyfXqw5RipKOVmcLzvgnaaMc4zyxqyA4'
                 * },
                 * ...
                 */
            ], 
            current: {
                category: null
            } 

        }
    }, 

    computed: {
        categories() {
            var all = [];

            this.collection.forEach((item) => {
                if(item.categories && item.categories != '' && item.categories.toLowerCase() != 'white label') {
                    let cats = item.categories.split(',')
                    all = all.concat(cats)
                }
            });
            var unique = [...new Set(all.sort())];
            // console.log(unique)

            return unique;
        }, 

        templates() {
            return this.collection.filter( (item) => {
                return item.categories.includes(this.current.category);
            });
        }
    },



    methods: {
        

        /*
		select(data) {
            let modal = this.$root.modal({
                title: 'Create a new page',
                context: data,
                okay: {
                    label: 'CREATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        let request = {...component.data, ...{
                                strategy_id: data.ID, 
                                title: this.titleSuffix + component.data.title
                            }
                        };

                        this.$store.commit('SET_LOADING_STATE', true);
                        let response = await this.$store.getters.wp.createStrategyForOpportunity(request);
                        await this.$store.dispatch('reloadStrategies');
                        this.$store.commit('SET_LOADING_STATE', false);

                        if(response?.ID) {
                            this.open(response);
                        }
                    }
                }, 

            }, 'CreateStrategyContent');

            setTimeout(() => {
                modal.content().data.title = data.title + ' (duplicate)';
                modal.content().data.excerpt = data.excerpt;
            }, 0);
		}, 
        */

       /**
        * 
        * @param {String} name the name of the action. Valid valuesa are "view", and "register"
        * @param {Object} data item data. Example
        * 
        * [{
        *     "id": "404",
        *     "thumbnailURL": "//content.app-sources.com/s/24444282803329/uploads/admin-files/17-2837592.jpg",
        *     "previewURL": "landing-17.web-repository.com",
        *     "name": "Landing Seventeen",
        *     "group": "Landing Page",
        *     "categories": "Video,Coming Soon",
        *     "registerURL": "/register?template=404&category=landing_page"
        * },
        * {...}
        * ]
        */
        action(name, data) {
            if(name in this) {
                this[name](data)
            }
        },


        view(data) {
            data

            console.log(data);

            this.$root.$refs.preview.open(data);
        },

        register(data) {
            let winName = 'register.' + Number(new Date());
            let url = 'https://appcropolis.com' + data.registerURL;
            window.open(url, winName);
        },

        // open(strategy) {
        //     let scope = this;
        //     this.$root.notify({
        //         type: 'confirm',
        //         title: 'Congratulations',
        //         message: `Your strategy "${strategy.title}" has been created. Would you like to open it?`,
        //         callback(okay) {
        //             if(okay) {
        //                 let path = `/edit/strategies/${strategy.kind}/${strategy.name}`;
        //                 scope.$router.push( {path: path} );
        //             }
        //         }
        //     });
        // }

    },



    async mounted() {
        // let kind = this.kind;
        // this.collection = await this.$store.dispatch('fetchStrategyTemplatesKind', kind);
        this.collection = WebTemplatesData;

        setTimeout(()=>{
            this.current.category = 'Business';
        }, 500)

        window.pages = this;
    }
}
</script>
<style lang="css">

.kind-page-wrapper {
    min-width: 832px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
    
.kind-page-wrapper .categories {
    width: 240px;
}
    
.kind-page-wrapper .kind-page {
}
</style>