<template lang="">
    <div class="card-email-wrapper mx-2 mb-5"
        :class="[data?.title.length? '' : 'invisible']">
        <a v-if="data?.title.length"
            class="content-list-items d-flex font-weight-normal text-decoration-none"
			@click="select(data)"
        >
            <div class="card-email bg-white shadow-sm">
                <div class="header">
                    <div class="border-bottom mb-2">
                        <h3 class="fw-500 mb-0 pb-0 user-select-none">{{ data.title }}</h3>
                        <p class="m-0 mb-1 p-0 font-size-13 text-muted user-select-none">{{ data.excerpt || '...' }}</p>
                    </div>
                    <div class="text-fields border-bottom text-muted">
                        <p class="mb-0">From: <span class="text-main bg-secondary field-email">{{ user?.user_email }}</span></p>
                        <p class="mb-1">To: </p>
                    </div>
                </div>

                <div class="body pt-2">
                    <!-- <div class="user-select-none content-preview" v-html="contentPreview"></div> -->
                    <ContentEditable 
                        :source="data.content" 
                        :highlight="true"
                        :theme="`theme-plain`"
                        class="pb-3 card-content user-select-none white-space-pre-line lh-base"></ContentEditable>
                </div>
            </div>
        </a>
    </div>
</template>



<script>
import ContentEditable from './ContentEditable.vue';


export default {
    name: "CardColdEmail",

    components: {
        ContentEditable
    },

    props: {
        data: Object,
        select: Function
    },

    computed: {
        user() {
            return this.$store.getters?.user;
        }, 

        contentPreview() {
            var splat = [];

            let preview = this.data.content.replace(/\n/g, '<br/>');
            preview.split('[').map((chunk)=>{
                if(chunk.indexOf(']') > -1) {
                    let parts = chunk.split(']');
                    let token = {
                        type: 'token',
                        value: parts[0] 
                    };
                    let text = {
                        type: 'text',
                        value: parts[1] 
                    };
                    splat.push(token);
                    splat.push(text);
                } else {
                    splat.push({ type: 'text', value: chunk})
                }  
            });

            if(splat.length > 1) {
                preview = '';
                splat.map( (part)=>{
                    if(part.type == 'text') {
                        preview += part.value;
                    } else if(part.type == 'token') {
                        preview += '<span class="px-2 rounded-pill text-primary text-token-sm">' + part.value + '</span>';
                    }
                });
            }
            
            return preview;
        }
    },



	methods: {

        text() {
            return this.contentPreview
        }
	},
    
}
</script>
<style lang="css" scoped>


.invisible {
    visibility: hidden;
}



.card-email-wrapper {
    width: 400px;
}

.card-email {
    min-width: 100%;
    padding: 15px 15px 0;
    border-radius: 8px;
    transition: all 0.15s;
	border: solid 1px transparent;
    max-width: 450px;
    border-radius: 8px;
    height: 300px;
    overflow: hidden;
    position: relative;
}

.card-email:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
}



.card-email:hover {
	cursor: pointer;
	border: solid 1px var(--primary);
}

.card-email .header {
    /* margin-bottom: 5px; */
}

.card-email .header h3 {
    color: var(--text-main);
    font-size: 15px;
    padding-bottom: 12px;
}

.card-email .text-fields {
    font-size: 13px;

}  

.card-email .text-fields .field-email {
    border-radius: 10px;
    padding: 1.5px 8px;
}    

.card-email .bg-secondary {
    background-color: var(--bg-secondary) !important;
}


.card-email .body {
    color: #565c65 !important;
    font-size: 14px;
}



.card-email .body p:last-child {
	margin-bottom: 0;
}


.card-email .footer {
    padding: 15px 0;
    font-size: 13px;
}
.card-email .footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.card-email .footer ul li {
	display: inline-block;
    color: var(--text-secondary);
    margin-right: 26px;
    font-size: 14px;
}
    
.white-space-pre-line {
    white-space: pre-line;
}
</style>