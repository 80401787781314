<template>
    <div class="card-fb-ad-wrapper mx-2 mb-5"
        :class="[data?.title.length? '' : 'invisible']"
        >
        <a v-if="data?.title.length"
            class="content-list-items d-flex h-100 font-weight-normal text-decoration-none"
			@click="select(data)"
        >

            <div class="card-fb-ad bg-white shadow-sm d-flex flex-column">
                <div class="header">
                    <div class="profile rounded-circle"></div>
                    <div class="text-holder">
                        <h3 class="font-weight-medium">{{ companyName }}</h3>
                        <span class="sponsor"
                            >Sponsored - <i class="fa fa-globe"></i
                        ></span>
                    </div>
                </div>

                <div class="body">
                    <ContentEditable 
                        :source="data.content" 
                        :highlight="true"
                        :theme="`theme-plain`"
                        class="card-content position-relative user-select-none pb-3 white-space-pre-line lh-base"></ContentEditable>


                    <figure class="wide mb-0 position-relative">
                        <img :src="data.thumbnail" class="img-fluid w-100"/>
                        <div class="card-description position-absolute top-0 start-0  w-100 h-50 p-3 text-white">
                            <h4 class="mb-0 fw-normal">{{data.title}}</h4>
                            <p class="fs-6">{{data.excerpt}}</p>
                        </div>
                    </figure>
                </div>

                <div class="border-color-light mt-auto footer py-3 text-center">
                    <ul>
                        <li>
							<!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.765 21.2001C12.285 21.2001 8.38504 20.3801 7.42504 19.7001C7.20504 19.5401 7.08505 19.3 7.08505 19.04V11.22C7.08505 10.98 7.20505 10.74 7.38505 10.6C7.44505 10.56 8.84505 9.46004 10.305 8.64004C12.125 7.64004 13.3851 6.24006 13.6851 5.36006C14.0451 4.28006 14.565 2.80005 16.205 2.80005C16.985 2.80005 17.625 3.24005 17.965 4.00005C18.645 5.56005 17.925 7.72004 16.725 9.42004C17.525 9.60004 18.525 9.86004 19.125 10.04C20.225 10.38 20.945 11.1801 21.105 12.1601C21.245 13.1601 20.7451 14.1801 19.6851 14.9601C18.9051 17.0601 17.5651 20.38 16.5851 20.92C16.2051 21.12 15.545 21.2001 14.765 21.2001ZM8.68505 18.5601C10.2051 19.2001 14.885 19.86 15.765 19.52C16.225 19.1 17.405 16.4201 18.225 14.1601C18.285 14.0001 18.385 13.8601 18.525 13.7601C19.185 13.3201 19.525 12.8001 19.465 12.3601C19.405 12.0001 19.085 11.7 18.605 11.54C17.605 11.22 15.1251 10.6801 15.0851 10.6801C14.8051 10.6201 14.5851 10.42 14.4851 10.14C14.3851 9.86004 14.465 9.56006 14.665 9.36006C16.465 7.42006 16.7851 5.44004 16.4451 4.64004C16.3451 4.40004 16.225 4.40005 16.165 4.40005C15.765 4.40005 15.585 4.68005 15.165 5.88005C14.685 7.28005 13.0251 8.96004 11.0451 10.04C10.0851 10.56 9.12505 11.26 8.64505 11.62V18.5601H8.68505ZM5.52504 19.12V11.14C5.52504 10.42 4.94504 9.84004 4.22504 9.84004C3.50504 9.84004 2.92504 10.42 2.92504 11.14V19.12C2.92504 19.84 3.50504 20.42 4.22504 20.42C4.92504 20.42 5.52504 19.82 5.52504 19.12Z" fill="black"/>
							</svg> -->
							<span>{{ rand() }} Likes</span>
						</li>

                        <li>{{ rand() }} Comments</li>
                        <li>{{ rand() }} Shares</li>
                    </ul>
                    <a href="#"></a>
                </div>
            </div>
        </a>
    </div>
</template>

<script>

import ContentEditable from './ContentEditable.vue';

export default {
    name: "CardFacebookAd",
    props: {
        data: Object,
        select: Function
    },

    components: {
        ContentEditable
    },

    computed: {
        companyName() {
            let name = this.$store.getters.company?.title;
            return name || '...';
        }
    },

	methods: {
        excerpt(content) {
            content = content?.length > 140? content.substr(0, 140) + '... more' : content;
            return content;
        }, 
        
        rand() {
            let val = Math.ceil( Math.random() * 250 );
            return val;
        }
	},

    async mounted() {
        setTimeout(() => {
            window.$(".card-fb-ad-wrapper .img-fluid").removeClass("img-fluid");
        }, 0);
    },
};
</script>

<style lang="css" scoped>
.invisible {
    visibility: hidden;
    
}

.card-content {
    min-height: 80px;
    max-height: 172px;
}

/** text cover */
.card-content:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
    background-image: linear-gradient(0deg, #fff, rgb(255 255 255 / 0) );
}

.card-fb-ad-wrapper {
    width: 400px;
}
figure {
    background-color: #92b6da;
}
figure img {
    opacity: 0.3;
}

.card-description {
    background-image: linear-gradient(180deg, hsl(216deg 73% 26% / 74%), rgba(0,0,0,0.0));
}

.card-description h4 {
    /* text-shadow: 0 1px 3px rgb(0 0 0 / 50%); */
}

.wide {
    margin-right: -15px !important;
    margin-left: -15px !important;
}
/* .card-fb-ad .body img.img-fluid,
.card-fb-ad .body img {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    margin-top: 10px;
} */
.card-fb-ad {
    min-width: 100%;
    height: auto;
    padding: 15px 15px 0;
    border-radius: 8px;
    transition: all 0.15s;
	border: solid 1px transparent;
}

.card-fb-ad:hover {
	cursor: pointer;
	border: solid 1px var(--primary);
}

.card-fb-ad .header {
    margin-bottom: 5px;
}
.card-fb-ad .header .profile {
    width: 42px;
    height: 42px;
    display: inline-block;
    background-color: var(--bg-secondary-darker);
    vertical-align: top;
    margin-right: 12px;
}
.card-fb-ad .header .text-holder {
    display: inline-block;
    margin-bottom: 15px;
}
.card-fb-ad .header .text-holder h3 {
    color: var(--text-main);
    font-size: 15px;
    margin: 6px 0 0;
    line-height: 10px;
}
.card-fb-ad .header .text-holder .sponsor {
    color: grey;
    font-size: 12px;
}
.card-fb-ad .body {
    color: #565c65 !important;
    font-size: 14px;
}
.card-fb-ad .body p:last-child {
	margin-bottom: 0;
}
.card-fb-ad .footer {
    padding: 15px 0;
    font-size: 13px;
}
.card-fb-ad .footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.card-fb-ad .footer ul li {
	display: inline-block;
    color: var(--text-secondary);
    margin-right: 26px;
    font-size: 14px;
}


.white-space-pre-line {
    white-space: pre-line;
}
</style>
