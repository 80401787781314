<template>
    <div class="app-header border-bottom">
        <nav class="navbar navbar-expand-lg navbar-light bg-white py-4">
            <div class="container-fluid d-flex flex-row px-4">
                
                <Brand></Brand>

                <div class="flex-fill"></div>

                <form @submit.prevent=""
                    v-if="false"
                    class="form-inline position-relative my-2 my-lg-0 mx-auto me-5"
                >
                    <input
                        class="form-control mr-sm-2 pl-5 bg-light w-50 pull-right d-none d-md-block btn-pill"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        disabled
                    />
                    <button
                        class="bg-transparent btn end-0 my-2 my-sm-0 position-absolute rounded-circle text-muted top-0"
                        type="submit"
                    >
                        <i class="fa fa-search ml-1"></i>
                    </button>
                </form>

                <!-- <router-link  to="/login" class="btn btn-outline-primary nav-link" href="#">Sign in</router-link> -->

                <div class="--navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item ms-2">
                            <IconButton class="" icon="help" data-bs-target="#panel-help" data-bs-toggle="offcanvas"></IconButton>
                        </li>

                        <li class="nav-item ms-2">
                            <IconButton class="" icon="settings" @click.prevent="goto($root.links.SETTINGS)"></IconButton>
                        </li>

                        <li class="nav-item ms-2">
                            <ButtonAppsDropdown></ButtonAppsDropdown>
                        </li>

                        <li class="nav-item ms-2">
                            <ButtonUserDropdown ></ButtonUserDropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import Brand from "@/components/Brand.vue";
import IconButton from "@/components/IconButton.vue";
import ButtonUserDropdown from "@/components/ButtonUserDropdown.vue";
import ButtonAppsDropdown from "@/components/ButtonAppsDropdown.vue";

export default {
    components: {
        Brand,
        IconButton,
        ButtonUserDropdown,
        ButtonAppsDropdown
    },
    name: "AppHeader",
    props: {},

    computed: {

        user() {
            return this.$store.getters.user;
        },


        profileImgURL() {
            let url = "https://dummyimage.com/36x36/fd7e14/FFFFFF?text=";
            if (this.user?.ID != null) {
                url += this.user.user_login.toUpperCase().substr(0, 1);
            }
            return url;
        },
    },

    methods: {
        showSettings() {
            window.$('#modal-user-settings').modal('show');
        }, 

        goto(url) {
            // let url = '/settings/company';
            this.$router.push( {path: url} );
        }
    }
};
</script>

<style scoped>
.app-header .form-inline {
    width: 50%;
}
.app-header .navbar-brand {
    font-size: 24px;
}
.app-header .nav-item i {
    font-size: 20px;
}

input[type="search"] {
    height: auto;
}

.btn-opportunities {
    background-color: #404fff;
    border-color: #404fff;
    margin-top: -3px;
	border-radius: 50px;
    color: #fff !important;
}


.btn-opportunities:hover {
    /* color: #fff !important; */
}

@media (max-width: 767.98px) {
    .app-header .form-inline {
        width: 18%;
    }
    .app-header .navbar-collapse {
        flex-basis: 0;
    }
    .app-header .navbar-nav {
        flex-direction: row;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .app-header .navbar-collapse {
        flex-basis: 0;
    }
    .app-header .navbar-nav {
        flex-direction: row;
    }
}
</style>
