<template>
    <div class="card shadow-sm"
        @click.stop="select(data, 'open', $event)"
        >
        <div class="card-body rounded-5 p-2rem">
            <div class="d-inline-block btn-edit end-0 float-end m-3 position-absolute top-0">
                <a class="btn-circle btn btn-secondary" @click.stop="select(data, 'edit', $event)">
                    <span class="material-icons-outlined position-absolute" style="margin-left: -12px;">
                    edit
                    </span>
                </a>
            </div>
            <img :src="`/resources/images/usecases/formula.svg`" class="rounded-circle mb-3" alt="">
            <h5 class="card-title text-black">{{ data.title }}</h5>
            <p class="card-text fs-6">{{ data.excerpt }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardFormula',

    props: {
        data: {
            type: Object,
            default() {
                return {
                    title: '...',
                    excerpt: '...'
                }
            }
        },
        select: {
            type: Function,
            default() {
                return function(){};
            }
        }
    }, 

    data() {
        return {

        }
    }

}
</script>

<style scoped>

.p-2rem {
    padding: 2rem!important;
}

.card {
    width: 32%;
    margin-right: 1%;
    /* background-color: #fcfcfd; */
    background-color: #c8d1da;
    transition: all 0.15s;
    order: 1px solid rgba(0,0,0,.05);
}
.card:hover {
    border-color: #aaa;
    /* background-color: #fff; */
    background-color: #d0dbe6;
    cursor: pointer;
    box-shadow: 0 4px 8px #404fff2b !important;
}
.card.active {
    pointer-events: none;
    background-color: #404fff;
    border-color: #404fff;
    color: #fff;
}


.card img {
    width: 64px;
    padding: 1px;
}

.card .btn-edit {
    transition: all 0.15s;
    opacity: 0;
}
.card:hover .btn-edit {
    opacity: 1;
}
</style>