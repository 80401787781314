<template lang="">
    <div class="content-editable --d-flex --flex-column --justify-content-center"
        :class="[mode() == modes.EDIT? `mode-edit ${theme}` : `mode-view ${theme}`]"
        >
        <div 
            v-html="preview" 
            ref="editor" 
            class="editor border-radius-4"
            :class="[current.display == 'output'? '' : 'd-none']"
            >
        </div>
        <div>
            <pre class="source edit-area border-radius-4 w-100 position-relative d-block" 
                ref="source" 
                :class="[current.display == 'source'? '' : 'd-none']"
                contenteditable>{{source}}</pre>
        </div>

        <div v-if="current.display == 'source'"
            style="margin: 4px 6px 0 0;"
            class="end-0 font-size-13 position-absolute rounded-3 top-0 text-secondary">SOURCE CODE</div>
    </div>
</template>
<script>


import {copyToClipboard} from '@/plugins/helper.js';


export default {
    name: 'ContentEditable',

    props: {
        update: Function,
        updateField: Function,
        updateFieldIntent: Function,
        source: String,
        tokens: Object,
        highlight: {
            type: Boolean,
            default: false
        },
        theme: {
            type: String,
            default: 'theme-normal'
        }
    },

    data() {
        return {
            selection: null,
            contentHTMLBefore: '',
            contentSourceBefore: '',
            hasChanged: false, 

            modes: {
                EDIT: 'edit',
                VIEW: 'view',
            },

            displays: {
                OUTPUT: 'output',
                SOURCE: 'source',
            },

            current: {
                mode: 'view', 
                theme: 'normal',
                display: 'output' /** valid values: source, output */
            }
        }
    }, 
    

    computed: {
        preview() {
            let content = this.source;
            let output  = this.tokens? this.strategyParser(content, this.tokens) : content;
                output  = this.highlight? this.addHighlight(output) : output;

            (function(scope) {
                setTimeout(()=>{
                    scope.parseTokens();
                }, 100);
            })(this);

            return output;
        }, 
    },


    methods: {
        /**
         * @param {*} content 
         * @param {*} tokens 
         */
        strategyParser(content, tokens) {
            if(!content || !tokens) {
                return content;
            }

            /**
            * replace the tokens for which values are defined
            */
            for (let token in tokens) {

                let re = new RegExp("\\[" + token + "\\]", "g");
                let value = tokens[token] && tokens[token].length > 0? tokens[token] : token;
                let unset = value == token? 'unset' : '';

                /**
                 * If token name is form.field.# (e.g. goals.gains.1), parse index based-one
                 */
                let tokenParts = token.split('.');
                let valueParts = value.split('|');
                if(tokenParts.length > 2) {
                    let index = parseInt(tokenParts[2]);
                    if(valueParts?.[index]) {
                        value = valueParts[index];
                    }
                }
                
                let replaceWith = `<span class="token assigned ${unset}" data-token="${token}">${value}</span>`;
                content = content.replace(re, replaceWith);
            }

            /**
            * make remanining tokens clickable
            */
            let unassigned = content.match(/\[[^\s]*\.[^\s\]]+\]/g);

            
            !!unassigned && unassigned.forEach((token)=>{
                // token
                let tokenRegExp = token
                    .replace('[', '\\[')
                    .replace(']', '\\]')
                    .replace(/\./, '\\.');

                let tokenName = token.replace(/(\[|\])/g, '');
                let replaceWith = `<span class="token unassigned unset" data-token="${tokenName}">${tokenName}</span>`;
                let re = new RegExp(tokenRegExp, "g");
                content = content.replace(re, replaceWith);
            });


            return content;
        },


        parseTokens() {
            var scope = this; 
            var $ = window.$;

            let $tokens = $(this.$refs.editor).find('.token.assigned');
            $tokens.each(function(i, item) {
                let $token = $(item);

                $token.unbind('click');
                $token.click(function() {
                    let sel = window.getSelection();
                        sel.removeAllRanges();
                    let $self = $(this);
                    let token = $self.attr('data-token');
                    let currentValue = $self.text() == token? '' : $self.text();

                    let modal = scope.updateFieldIntent(token, currentValue);

                    /**
                     * Set up "unlink" event listener
                     */
                    setTimeout(()=>{
                        let content = modal.content();
                        window.$(content.$el).unbind('unlink').bind('unlink', (e, {value})=>{
                            e;
                            let tokenValue = value.length? value : $token.text();
                            $token.replaceWith(tokenValue);
                            scope.update();
                        });
                    }, 250);

                    return false;
                });

            });
        },



        addHighlight(content) {
            let highlightable = content.match(/\[[^\]]+\]/g);

            
            !(!highlightable) && highlightable.forEach((token)=>{
                // token
                let tokenRegExp = token
                    .replace('[', '\\[')
                    .replace(']', '\\]');

                let tokenName = token.replace(/(\[|\])/g, '');
                let replaceWith = `<span class="token border-0 highlight text-primary text-token-sm rounded-pill" data-token="${tokenName}">${tokenName}</span>`;
                let re = new RegExp(tokenRegExp, "g");
                content = content.replace(re, replaceWith);
            });
            
            return content;

        },


        stringifyTokens(html) {
            var $ = window.$;
            let wrapper = $('<div/>');
                wrapper.append(html);   
            var tokens = wrapper.find('.token');
                tokens.each(function(i, item) {
                    let tokenName = $(item).attr('data-token');
                    $(item).replaceWith('['+ tokenName + ']');
                });
            
            let content = wrapper.html();
            return content;
        },




        display(display) {
            if(!display) {
                return this.current?.display;
            }

            if(display == 'source') {
                let currentHTML = this.$refs.editor.innerHTML;
                let textContent = this.stringifyTokens(currentHTML);
                this.$refs.source.textContent = textContent;
            }

            this.current.display = display;
        },


        mode(mode) {
            if(!mode) {
                return this.current?.mode;
            }

            this.current.mode = mode;
        },
    

        copy() {
            var editor = this.$refs.editor;
            var tokens = editor.querySelectorAll('.token');
                tokens.forEach((token)=>{
                    let $token = window.$(token);
                        $token.removeClass('token');
                        $token.addClass('font-weight-bold');
                });

            copyToClipboard(editor, (selection)=>{
                let message = 'The content has been copied to your clipboard. You can now open your email/text editor and paste it (Cmd+v).'
                this.$root.notify({
                    title: 'Yay!', 
                    type: 'alert', 
                    message: message, 
                    callback: ()=> {
                        selection.removeAllRanges();
                        tokens.forEach((token)=>{
                            let $token = window.$(token);
                                $token.addClass('token');
                                $token.removeClass('font-weight-bold');
                        });
                    }
                });
            });
        }, 
        

        /**
         * @see: https://stackoverflow.com/questions/2920150/insert-text-at-cursor-in-a-content-editable-div
         */
        saveSelection() {
            var sel;
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    return sel.getRangeAt(0);
                }
            } else if (document.selection && document.selection.createRange) {
                return document.selection.createRange();
            }
            return null;
        },

        restoreSelection(range) {
            var sel;
            if (range) {
                if (window.getSelection) {
                    sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                } else if (document.selection && range.select) {
                    range.select();
                }
            }
        },

        insertTextAtCaret(text) {
            var sel, range;
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0);
                    range.deleteContents();
                    range.insertNode( document.createTextNode(text) );
                }
            } else if (document.selection && document.selection.createRange) {
                document.selection.createRange().text = text;
            }
        },


        insertTokenIntent() {
            this.selection = this.saveSelection();

            let scope = this;
            this.$root.notify({
                title: 'Insert token', 
                type: 'prompt', 
                message: 'Enter the token you wish to insert (e.g. [company.name])', 
                value: '[company.name]',
                callback: (okay, value)=> {
                    if(okay) {
                        scope.insertToken(value);
                    }
                }
            });
        },

        insertToken(token) {
            this.restoreSelection(this.selection);
            this.insertTextAtCaret(' ' + token + ' ');
            this.selection = null;
        },


        edit() {
            this.contentHTMLBefore = this.$refs.editor.innerHTML;
            this.contentSourceBefore = this.$refs.source.value;
            this.$refs.editor.setAttribute('contenteditable', true);
            this.hasChanged = false;
            this.mode(this.modes.EDIT);
        },

        cancel() {
            this.$refs.editor.innerHTML = this.contentHTMLBefore;
            this.$refs.editor.removeAttribute('contenteditable');
            this.$refs.source.value = this.contentSourceBefore;
            this.hasChanged = false;
            this.display(this.displays.OUTPUT);
            this.mode(this.modes.VIEW);
            this.parseTokens();
        },

        reset() {
            this.contentHTMLBefore = '';
            this.contentSourceBefore = '';
            this.$refs.editor.removeAttribute('contenteditable');
            this.hasChanged = false;
            this.display(this.displays.OUTPUT);
            this.mode(this.modes.VIEW);
        },



        content() {
            let html, content;
            switch (this.current?.display) {
                case 'output':
                    html = this.$refs.editor.innerHTML;
                    content = this.stringifyTokens(html);
                    this.$refs.source.textContent = content;
                    break;
            
                case 'source':
                    content = this.$refs.source.textContent;
                    break;

                default:
                    break;
            }
            return content;
        },


        text() {
            let text = this.$refs.editor.textContent;
            return text;
        },

    },

    mounted() {
        
        this.$refs.editor.addEventListener('input', ()=> {
            // this.$refs.strategyEditor.classList.add('changed');
            this.hasChanged = true;
        }, false);


        this.$refs.source.addEventListener('input', ()=> {
            this.hasChanged = true;
        }, false);


        window.editable = this;
    },
}
</script>
<style lang="css">

.content-editable.theme-normal .editor {
    padding: 15px;
    border: solid 1px rgba(0,0,0,.05);
}
.content-editable.theme-plain .editor {
    padding: 0px;
    border: none;
}


.content-editable .editor {
    transition: all 0.2s;
    pointer-events: none;
}

.content-editable textarea {
    height: auto;
}

.content-editable .edit-area {
    white-space: pre-wrap;
    padding: 15px;
    border: solid 1px rgba(0,0,0,.05);
    transition: all 0.2s;
}

.content-editable .editor,
.content-editable .source {
    outline: solid 1px rgba(0,0,0,0);
}

.content-editable.mode-edit .editor,
.content-editable.mode-edit .source {
    background-color:lemonchiffon;
    outline: solid 1px rgba(0,0,0,.1);
}

.content-editable .token {
    padding-left: 5px;
    padding-right: 5px;
    display: unset;
    border: solid 1px #e2e5e5;
    background-color: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    pointer-events: all !important;
}

.content-editable .token.unassigned {
    border: solid 1px #f5c6ca;
    color: var(--danger);
}

.content-editable.mode-edit .editor {
    pointer-events: all;
}

</style>