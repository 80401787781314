<template>
    <div class="card shadow-sm">
        <div class="card-body rounded-5 p-1">
            <img :src="data.thumbnailURL" class="mb-3" alt="">
            <h5 class="card-title text-black px-3 pt-1 pb-2">{{ data.name }}</h5>
            <div class="cover rounded-5 d-flex align-items-center justify-content-center">
                <div class="d-flex flex-row justify-content-center">
                    <a class="btn btn-primary btn-pill btn-lg mx-2 px-4"
                        @click.prevent="action('view', data)"
                        >View</a>
                    <a class="btn btn-primary btn-pill btn-lg px-3"
                        @click.prevent="action('register', data)"
                        >Get Started</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        data: {
            type: Object,
            default() {
                return {
                    name: '...',
                    description: '...'
                }
            }
        },
        action: {
            type: Function,
            default() {
                return function(){};
            }
        }
    }, 

    data() {
        return {

        }
    }

}
</script>

<style scoped>

.p-2rem {
    padding: 2rem!important;
}

.card {
    width: 32%;
    margin-right: 1%;
    background-color: #fcfcfd;
    transition: all 0.15s;
}
.card:hover {
    border-color: #aaa;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 4px 8px #404fff2b !important;
}
.card.active {
    pointer-events: none;
    background-color: #404fff;
    border-color: #404fff;
    color: #fff;
}


.card img {
    width: 100%;
    aspect-ratio: 5/5;
    object-fit: cover;
    object-position: top;
    transition: all 1s;
}

.card img:hover {
    object-position: bottom;
}


.card .cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, .75);
    opacity: 0;
    transition: opacity 0.3s; 
}

.card .cover:hover {
    opacity: 1;
}
</style>