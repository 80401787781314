<template>
    <div class="--navbar-brand text-dark">
        <AppLogo></AppLogo>
        
        <div  v-if="$store.state.loading" class="border-2 ms-3 position-absolute spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Brand'
    }
</script>

<style lang="css" scoped>
    .brand-name {
        font-family: 'Montserrat Alternates', sans-serif;
    }

    .navbar-brand svg {
        margin-top: -3px;
        /* margin-right: 10px */
    }
</style>